var exports = {};
exports = {
  A: {
    A: {
      J: 0.0131217,
      E: 0.00621152,
      F: 0.0439988,
      G: 0.0527986,
      A: 0.00879976,
      B: 0.36959,
      GC: 0.009298
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "GC", "J", "E", "F", "G", "A", "B", "", "", ""],
    E: "IE",
    F: {
      GC: 962323200,
      J: 998870400,
      E: 1161129600,
      F: 1237420800,
      G: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      C: 0.004081,
      K: 0.004267,
      L: 0.004268,
      H: 0.004081,
      M: 0.003702,
      N: 0.004441,
      O: 0.013323,
      P: 0,
      Q: 0.004298,
      R: 0.00944,
      S: 0.004043,
      T: 0.004441,
      U: 0.003861,
      V: 0.003861,
      W: 0.004441,
      X: 0.003943,
      Y: 0.004441,
      Z: 0.003943,
      a: 0.003943,
      b: 0.008882,
      c: 0.004118,
      d: 0.003939,
      e: 0.003943,
      f: 0.003943,
      g: 0.003943,
      h: 0.003929,
      i: 0.003901,
      m: 0.011829,
      n: 0.004441,
      o: 0.004441,
      p: 0.008162,
      q: 0.004081,
      r: 0.004441,
      s: 0.008882,
      t: 0.017764,
      u: 0.031087,
      v: 0.093261,
      w: 0.075497,
      x: 1.38559,
      y: 3.15755,
      D: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "K", "L", "H", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "D", "", "", ""],
    E: "Edge",
    F: {
      C: 1438128000,
      K: 1447286400,
      L: 1470096000,
      H: 1491868800,
      M: 1508198400,
      N: 1525046400,
      O: 1542067200,
      P: 1579046400,
      Q: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      m: 1649635200,
      n: 1651190400,
      o: 1653955200,
      p: 1655942400,
      q: 1659657600,
      r: 1661990400,
      s: 1664755200,
      t: 1666915200,
      u: 1670198400,
      v: 1673481600,
      w: 1675900800,
      x: 1678665600,
      y: 1680825600,
      D: 1683158400
    },
    D: {
      C: "ms",
      K: "ms",
      L: "ms",
      H: "ms",
      M: "ms",
      N: "ms",
      O: "ms"
    }
  },
  C: {
    A: {
      "0": 0.004443,
      "1": 0.013698,
      "2": 0.004161,
      "3": 0.008786,
      "4": 0.004118,
      "5": 0.004317,
      "6": 0.004393,
      "7": 0.004418,
      "8": 0.008834,
      "9": 0.008322,
      HC: 0.004118,
      wB: 0.004271,
      I: 0.011703,
      z: 0.004879,
      J: 0.020136,
      E: 0.005725,
      F: 0.004525,
      G: 0.00533,
      A: 0.004283,
      B: 0.008882,
      C: 0.004471,
      K: 0.004486,
      L: 0.00453,
      H: 0.008322,
      M: 0.004417,
      N: 0.004425,
      O: 0.004161,
      j: 0.004283,
      k: 0.008322,
      AB: 0.008928,
      BB: 0.004471,
      CB: 0.009284,
      DB: 0.004707,
      EB: 0.009076,
      FB: 0.004081,
      GB: 0.004783,
      HB: 0.003929,
      IB: 0.004783,
      JB: 0.00487,
      KB: 0.005029,
      LB: 0.0047,
      MB: 0.022205,
      NB: 0.004441,
      OB: 0.003867,
      PB: 0.004525,
      QB: 0.004293,
      RB: 0.004081,
      SB: 0.004538,
      TB: 0.008282,
      UB: 0.011601,
      VB: 0.039969,
      WB: 0.011601,
      XB: 0.004441,
      YB: 0.004441,
      ZB: 0.004441,
      aB: 0.011601,
      bB: 0.003939,
      xB: 0.004441,
      cB: 0.003929,
      yB: 0.004356,
      dB: 0.004425,
      eB: 0.008322,
      fB: 0.00415,
      gB: 0.004267,
      hB: 0.003801,
      iB: 0.004267,
      jB: 0.004081,
      kB: 0.00415,
      lB: 0.004293,
      mB: 0.004425,
      nB: 0.013323,
      l: 0.00415,
      oB: 0.00415,
      pB: 0.004318,
      qB: 0.004356,
      rB: 0.003974,
      sB: 0.031087,
      P: 0.004081,
      Q: 0.004081,
      R: 0.004081,
      zB: 0.003861,
      S: 0.004441,
      T: 0.003929,
      U: 0.004268,
      V: 0.003801,
      W: 0.008882,
      X: 0.004441,
      Y: 0.003943,
      Z: 0.003943,
      a: 0.008882,
      b: 0.003801,
      c: 0.007722,
      d: 0.017764,
      e: 0.003773,
      f: 0.007886,
      g: 0.003901,
      h: 0.003901,
      i: 0.004081,
      m: 0.003861,
      n: 0.004081,
      o: 0.097702,
      p: 0.017764,
      q: 0.004441,
      r: 0.008882,
      s: 0.008882,
      t: 0.008882,
      u: 0.013323,
      v: 0.022205,
      w: 0.048851,
      x: 1.00367,
      y: 0.905964,
      D: 0.008882,
      "0B": 0,
      "1B": 0,
      IC: 0.008786,
      JC: 0.00487
    },
    B: "moz",
    C: ["HC", "wB", "IC", "JC", "I", "z", "J", "E", "F", "G", "A", "B", "C", "K", "L", "H", "M", "N", "O", "0", "j", "k", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "xB", "cB", "yB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "l", "oB", "pB", "qB", "rB", "sB", "P", "Q", "R", "zB", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "D", "0B", "1B", ""],
    E: "Firefox",
    F: {
      "0": 1357603200,
      "1": 1368489600,
      "2": 1372118400,
      "3": 1375747200,
      "4": 1379376000,
      "5": 1386633600,
      "6": 1391472000,
      "7": 1395100800,
      "8": 1398729600,
      "9": 1402358400,
      HC: 1161648000,
      wB: 1213660800,
      IC: 1246320000,
      JC: 1264032000,
      I: 1300752000,
      z: 1308614400,
      J: 1313452800,
      E: 1317081600,
      F: 1317081600,
      G: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      K: 1335225600,
      L: 1338854400,
      H: 1342483200,
      M: 1346112000,
      N: 1349740800,
      O: 1353628800,
      j: 1361232000,
      k: 1364860800,
      AB: 1405987200,
      BB: 1409616000,
      CB: 1413244800,
      DB: 1417392000,
      EB: 1421107200,
      FB: 1424736000,
      GB: 1428278400,
      HB: 1431475200,
      IB: 1435881600,
      JB: 1439251200,
      KB: 1442880000,
      LB: 1446508800,
      MB: 1450137600,
      NB: 1453852800,
      OB: 1457395200,
      PB: 1461628800,
      QB: 1465257600,
      RB: 1470096000,
      SB: 1474329600,
      TB: 1479168000,
      UB: 1485216000,
      VB: 1488844800,
      WB: 1492560000,
      XB: 1497312000,
      YB: 1502150400,
      ZB: 1506556800,
      aB: 1510617600,
      bB: 1516665600,
      xB: 1520985600,
      cB: 1525824000,
      yB: 1529971200,
      dB: 1536105600,
      eB: 1540252800,
      fB: 1544486400,
      gB: 1548720000,
      hB: 1552953600,
      iB: 1558396800,
      jB: 1562630400,
      kB: 1567468800,
      lB: 1571788800,
      mB: 1575331200,
      nB: 1578355200,
      l: 1581379200,
      oB: 1583798400,
      pB: 1586304000,
      qB: 1588636800,
      rB: 1591056000,
      sB: 1593475200,
      P: 1595894400,
      Q: 1598313600,
      R: 1600732800,
      zB: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      m: 1651536000,
      n: 1653955200,
      o: 1656374400,
      p: 1658793600,
      q: 1661212800,
      r: 1663632000,
      s: 1666051200,
      t: 1668470400,
      u: 1670889600,
      v: 1673913600,
      w: 1676332800,
      x: 1678752000,
      y: 1681171200,
      D: 1683590400,
      "0B": null,
      "1B": null
    }
  },
  D: {
    A: {
      "0": 0.008322,
      "1": 0.003901,
      "2": 0.008786,
      "3": 0.003939,
      "4": 0.004461,
      "5": 0.004141,
      "6": 0.004326,
      "7": 0.0047,
      "8": 0.004538,
      "9": 0.008322,
      I: 0.004706,
      z: 0.004879,
      J: 0.004879,
      E: 0.005591,
      F: 0.005591,
      G: 0.005591,
      A: 0.004534,
      B: 0.004464,
      C: 0.010424,
      K: 0.0083,
      L: 0.004706,
      H: 0.015087,
      M: 0.004393,
      N: 0.004393,
      O: 0.008652,
      j: 0.004393,
      k: 0.004317,
      AB: 0.008596,
      BB: 0.004566,
      CB: 0.004118,
      DB: 0.008882,
      EB: 0.004441,
      FB: 0.004335,
      GB: 0.004464,
      HB: 0.017764,
      IB: 0.003867,
      JB: 0.013323,
      KB: 0.004441,
      LB: 0.003974,
      MB: 0.008882,
      NB: 0.008882,
      OB: 0.013323,
      PB: 0.003867,
      QB: 0.008882,
      RB: 0.017764,
      SB: 0.035528,
      TB: 0.004441,
      UB: 0.004081,
      VB: 0.004441,
      WB: 0.008882,
      XB: 0.003867,
      YB: 0.004441,
      ZB: 0.066615,
      aB: 0.004081,
      bB: 0.004441,
      xB: 0.003773,
      cB: 0.013323,
      yB: 0.008882,
      dB: 0.003773,
      eB: 0.004441,
      fB: 0.003943,
      gB: 0.008882,
      hB: 0.031087,
      iB: 0.008882,
      jB: 0.013323,
      kB: 0.039969,
      lB: 0.022205,
      mB: 0.017764,
      nB: 0.026646,
      l: 0.008882,
      oB: 0.031087,
      pB: 0.04441,
      qB: 0.04441,
      rB: 0.017764,
      sB: 0.026646,
      P: 0.22205,
      Q: 0.039969,
      R: 0.04441,
      S: 0.137671,
      T: 0.035528,
      U: 0.071056,
      V: 0.057733,
      W: 0.093261,
      X: 0.026646,
      Y: 0.035528,
      Z: 0.04441,
      a: 0.084379,
      b: 0.048851,
      c: 0.137671,
      d: 0.066615,
      e: 0.017764,
      f: 0.035528,
      g: 0.048851,
      h: 0.039969,
      i: 0.057733,
      m: 0.048851,
      n: 0.039969,
      o: 0.057733,
      p: 0.270901,
      q: 0.057733,
      r: 0.08882,
      s: 0.071056,
      t: 0.093261,
      u: 0.248696,
      v: 2.10947,
      w: 0.475187,
      x: 8.75321,
      y: 9.67694,
      D: 0.022205,
      "0B": 0.013323,
      "1B": 0,
      KC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "I", "z", "J", "E", "F", "G", "A", "B", "C", "K", "L", "H", "M", "N", "O", "0", "j", "k", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "xB", "cB", "yB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "l", "oB", "pB", "qB", "rB", "sB", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "D", "0B", "1B", "KC"],
    E: "Chrome",
    F: {
      "0": 1332892800,
      "1": 1343692800,
      "2": 1348531200,
      "3": 1352246400,
      "4": 1357862400,
      "5": 1361404800,
      "6": 1364428800,
      "7": 1369094400,
      "8": 1374105600,
      "9": 1376956800,
      I: 1264377600,
      z: 1274745600,
      J: 1283385600,
      E: 1287619200,
      F: 1291248000,
      G: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      K: 1312243200,
      L: 1316131200,
      H: 1316131200,
      M: 1319500800,
      N: 1323734400,
      O: 1328659200,
      j: 1337040000,
      k: 1340668800,
      AB: 1384214400,
      BB: 1389657600,
      CB: 1392940800,
      DB: 1397001600,
      EB: 1400544000,
      FB: 1405468800,
      GB: 1409011200,
      HB: 1412640000,
      IB: 1416268800,
      JB: 1421798400,
      KB: 1425513600,
      LB: 1429401600,
      MB: 1432080000,
      NB: 1437523200,
      OB: 1441152000,
      PB: 1444780800,
      QB: 1449014400,
      RB: 1453248000,
      SB: 1456963200,
      TB: 1460592000,
      UB: 1464134400,
      VB: 1469059200,
      WB: 1472601600,
      XB: 1476230400,
      YB: 1480550400,
      ZB: 1485302400,
      aB: 1489017600,
      bB: 1492560000,
      xB: 1496707200,
      cB: 1500940800,
      yB: 1504569600,
      dB: 1508198400,
      eB: 1512518400,
      fB: 1516752000,
      gB: 1520294400,
      hB: 1523923200,
      iB: 1527552000,
      jB: 1532390400,
      kB: 1536019200,
      lB: 1539648000,
      mB: 1543968000,
      nB: 1548720000,
      l: 1552348800,
      oB: 1555977600,
      pB: 1559606400,
      qB: 1564444800,
      rB: 1568073600,
      sB: 1571702400,
      P: 1575936000,
      Q: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      m: 1648512000,
      n: 1650931200,
      o: 1653350400,
      p: 1655769600,
      q: 1659398400,
      r: 1661817600,
      s: 1664236800,
      t: 1666656000,
      u: 1669680000,
      v: 1673308800,
      w: 1675728000,
      x: 1678147200,
      y: 1680566400,
      D: 1682985600,
      "0B": null,
      "1B": null,
      KC: null
    }
  },
  E: {
    A: {
      I: 0,
      z: 0.008322,
      J: 0.004656,
      E: 0.004465,
      F: 0.003974,
      G: 0.003929,
      A: 0.004425,
      B: 0.004318,
      C: 0.003801,
      K: 0.022205,
      L: 0.119907,
      H: 0.026646,
      LC: 0,
      "2B": 0.008692,
      MC: 0.008882,
      NC: 0.00456,
      OC: 0.004283,
      PC: 0.048851,
      "3B": 0.007802,
      tB: 0.008882,
      uB: 0.039969,
      "4B": 0.186522,
      QC: 0.328634,
      RC: 0.048851,
      "5B": 0.04441,
      "6B": 0.111025,
      "7B": 0.195404,
      "8B": 0.830467,
      vB: 0.08882,
      "9B": 0.279783,
      AC: 0.408572,
      BC: 1.63429,
      CC: 0.688355,
      DC: 0.013323,
      SC: 0,
      TC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "LC", "2B", "I", "z", "MC", "J", "NC", "E", "OC", "F", "G", "PC", "A", "3B", "B", "tB", "C", "uB", "K", "4B", "L", "QC", "H", "RC", "5B", "6B", "7B", "8B", "vB", "9B", "AC", "BC", "CC", "DC", "SC", "TC", ""],
    E: "Safari",
    F: {
      LC: 1205798400,
      "2B": 1226534400,
      I: 1244419200,
      z: 1275868800,
      MC: 1311120000,
      J: 1343174400,
      NC: 1382400000,
      E: 1382400000,
      OC: 1410998400,
      F: 1413417600,
      G: 1443657600,
      PC: 1458518400,
      A: 1474329600,
      "3B": 1490572800,
      B: 1505779200,
      tB: 1522281600,
      C: 1537142400,
      uB: 1553472000,
      K: 1568851200,
      "4B": 1585008000,
      L: 1600214400,
      QC: 1619395200,
      H: 1632096000,
      RC: 1635292800,
      "5B": 1639353600,
      "6B": 1647216000,
      "7B": 1652745600,
      "8B": 1658275200,
      vB: 1662940800,
      "9B": 1666569600,
      AC: 1670889600,
      BC: 1674432000,
      CC: 1679875200,
      DC: 1684368000,
      SC: null,
      TC: null
    }
  },
  F: {
    A: {
      "0": 0.006015,
      "1": 0.006597,
      "2": 0.013434,
      "3": 0.006702,
      "4": 0.006015,
      "5": 0.005595,
      "6": 0.004393,
      "7": 0.008882,
      "8": 0.004879,
      "9": 0.004879,
      G: 0.0082,
      B: 0.016581,
      C: 0.004317,
      H: 0.00685,
      M: 0.00685,
      N: 0.00685,
      O: 0.005014,
      j: 0.004879,
      k: 0.006597,
      AB: 0.004441,
      BB: 0.005152,
      CB: 0.005014,
      DB: 0.009758,
      EB: 0.004879,
      FB: 0.004441,
      GB: 0.004283,
      HB: 0.004367,
      IB: 0.004534,
      JB: 0.004441,
      KB: 0.004227,
      LB: 0.004418,
      MB: 0.004161,
      NB: 0.004227,
      OB: 0.004725,
      PB: 0.013323,
      QB: 0.008942,
      RB: 0.004707,
      SB: 0.004827,
      TB: 0.004707,
      UB: 0.004707,
      VB: 0.004326,
      WB: 0.008922,
      XB: 0.014349,
      YB: 0.004425,
      ZB: 0.00472,
      aB: 0.004425,
      bB: 0.004425,
      cB: 0.00472,
      dB: 0.004532,
      eB: 0.004566,
      fB: 0.02283,
      gB: 0.00867,
      hB: 0.004656,
      iB: 0.004642,
      jB: 0.003929,
      kB: 0.00944,
      lB: 0.004293,
      mB: 0.003929,
      nB: 0.004298,
      l: 0.096692,
      oB: 0.008162,
      pB: 0.004141,
      qB: 0.004257,
      rB: 0.003939,
      sB: 0.008236,
      P: 0.003855,
      Q: 0.003939,
      R: 0.008514,
      zB: 0.003939,
      S: 0.003939,
      T: 0.003702,
      U: 0.004441,
      V: 0.003855,
      W: 0.003855,
      X: 0.003929,
      Y: 0.003861,
      Z: 0.011703,
      a: 0.007546,
      b: 0.011829,
      c: 0.069498,
      d: 0.004441,
      e: 0.066615,
      f: 0.315311,
      g: 0.817144,
      h: 0.031087,
      i: 0,
      UC: 0.00685,
      VC: 0,
      WC: 0.008392,
      XC: 0.004706,
      tB: 0.006229,
      EC: 0.004879,
      YC: 0.008786,
      uB: 0.00472
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "G", "UC", "VC", "WC", "XC", "B", "tB", "EC", "YC", "C", "uB", "H", "M", "N", "O", "0", "j", "k", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "l", "oB", "pB", "qB", "rB", "sB", "P", "Q", "R", "zB", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "", "", ""],
    E: "Opera",
    F: {
      "0": 1390867200,
      "1": 1401753600,
      "2": 1405987200,
      "3": 1409616000,
      "4": 1413331200,
      "5": 1417132800,
      "6": 1422316800,
      "7": 1425945600,
      "8": 1430179200,
      "9": 1433808000,
      G: 1150761600,
      UC: 1223424000,
      VC: 1251763200,
      WC: 1267488000,
      XC: 1277942400,
      B: 1292457600,
      tB: 1302566400,
      EC: 1309219200,
      YC: 1323129600,
      C: 1323129600,
      uB: 1352073600,
      H: 1372723200,
      M: 1377561600,
      N: 1381104000,
      O: 1386288000,
      j: 1393891200,
      k: 1399334400,
      AB: 1438646400,
      BB: 1442448000,
      CB: 1445904000,
      DB: 1449100800,
      EB: 1454371200,
      FB: 1457308800,
      GB: 1462320000,
      HB: 1465344000,
      IB: 1470096000,
      JB: 1474329600,
      KB: 1477267200,
      LB: 1481587200,
      MB: 1486425600,
      NB: 1490054400,
      OB: 1494374400,
      PB: 1498003200,
      QB: 1502236800,
      RB: 1506470400,
      SB: 1510099200,
      TB: 1515024000,
      UB: 1517961600,
      VB: 1521676800,
      WB: 1525910400,
      XB: 1530144000,
      YB: 1534982400,
      ZB: 1537833600,
      aB: 1543363200,
      bB: 1548201600,
      cB: 1554768000,
      dB: 1561593600,
      eB: 1566259200,
      fB: 1570406400,
      gB: 1573689600,
      hB: 1578441600,
      iB: 1583971200,
      jB: 1587513600,
      kB: 1592956800,
      lB: 1595894400,
      mB: 1600128000,
      nB: 1603238400,
      l: 1613520000,
      oB: 1612224000,
      pB: 1616544000,
      qB: 1619568000,
      rB: 1623715200,
      sB: 1627948800,
      P: 1631577600,
      Q: 1633392000,
      R: 1635984000,
      zB: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200
    },
    D: {
      G: "o",
      B: "o",
      C: "o",
      UC: "o",
      VC: "o",
      WC: "o",
      XC: "o",
      tB: "o",
      EC: "o",
      YC: "o",
      uB: "o"
    }
  },
  G: {
    A: {
      F: 0.00318601,
      "2B": 0,
      ZC: 0,
      FC: 0.00318601,
      aC: 0.00477902,
      bC: 0.00637202,
      cC: 0.0175231,
      dC: 0.0254881,
      eC: 0.012744,
      fC: 0.0541622,
      gC: 0.00318601,
      hC: 0.0684992,
      iC: 0.0207091,
      jC: 0.0223021,
      kC: 0.0191161,
      lC: 0.358426,
      mC: 0.011151,
      nC: 0.0207091,
      oC: 0.0302671,
      pC: 0.0908013,
      qC: 0.237358,
      rC: 0.430112,
      sC: 0.136998,
      "5B": 0.167266,
      "6B": 0.191161,
      "7B": 0.30745,
      "8B": 0.831549,
      vB: 0.933501,
      "9B": 1.93709,
      AC: 1.14696,
      BC: 5.44011,
      CC: 2.42455,
      DC: 0.0525692
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "2B", "ZC", "FC", "aC", "bC", "cC", "F", "dC", "eC", "fC", "gC", "hC", "iC", "jC", "kC", "lC", "mC", "nC", "oC", "pC", "qC", "rC", "sC", "5B", "6B", "7B", "8B", "vB", "9B", "AC", "BC", "CC", "DC", "", "", ""],
    E: "Safari on iOS",
    F: {
      "2B": 1270252800,
      ZC: 1283904000,
      FC: 1299628800,
      aC: 1331078400,
      bC: 1359331200,
      cC: 1394409600,
      F: 1410912000,
      dC: 1413763200,
      eC: 1442361600,
      fC: 1458518400,
      gC: 1473724800,
      hC: 1490572800,
      iC: 1505779200,
      jC: 1522281600,
      kC: 1537142400,
      lC: 1553472000,
      mC: 1568851200,
      nC: 1572220800,
      oC: 1580169600,
      pC: 1585008000,
      qC: 1600214400,
      rC: 1619395200,
      sC: 1632096000,
      "5B": 1639353600,
      "6B": 1647216000,
      "7B": 1652659200,
      "8B": 1658275200,
      vB: 1662940800,
      "9B": 1666569600,
      AC: 1670889600,
      BC: 1674432000,
      CC: 1679875200,
      DC: 1684368000
    }
  },
  H: {
    A: {
      tC: 0.994689
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "tC", "", "", ""],
    E: "Opera Mini",
    F: {
      tC: 1426464000
    }
  },
  I: {
    A: {
      wB: 0,
      I: 0.0285433,
      D: 0,
      uC: 0,
      vC: 0.00951444,
      wC: 0,
      xC: 0.0190289,
      FC: 0.0951444,
      yC: 0,
      zC: 0.34252
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "uC", "vC", "wC", "wB", "I", "xC", "FC", "yC", "zC", "D", "", "", ""],
    E: "Android Browser",
    F: {
      uC: 1256515200,
      vC: 1274313600,
      wC: 1291593600,
      wB: 1298332800,
      I: 1318896000,
      xC: 1341792000,
      FC: 1374624000,
      yC: 1386547200,
      zC: 1401667200,
      D: 1682985600
    }
  },
  J: {
    A: {
      E: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "E", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      E: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      l: 0.0111391,
      tB: 0,
      EC: 0,
      uB: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "tB", "EC", "C", "uB", "l", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      tB: 1314835200,
      EC: 1318291200,
      C: 1330300800,
      uB: 1349740800,
      l: 1673827200
    },
    D: {
      l: "webkit"
    }
  },
  L: {
    A: {
      D: 39.6882
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "", "", ""],
    E: "Chrome for Android",
    F: {
      D: 1682985600
    }
  },
  M: {
    A: {
      D: 0.289068
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "", "", ""],
    E: "Firefox for Android",
    F: {
      D: 1683590400
    }
  },
  N: {
    A: {
      A: 0.0115934,
      B: 0.022664
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      "0C": 0.950589
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "0C", "", "", ""],
    E: "UC Browser for Android",
    F: {
      "0C": 1634688000
    },
    D: {
      "0C": "webkit"
    }
  },
  P: {
    A: {
      I: 0.191027,
      j: 2.06946,
      k: 0,
      "1C": 0.0103543,
      "2C": 0.010304,
      "3C": 0.0530632,
      "4C": 0.0103584,
      "5C": 0.0104443,
      "3B": 0.0105043,
      "6C": 0.0212253,
      "7C": 0.0103982,
      "8C": 0.0212253,
      "9C": 0.0106126,
      AD: 0.0106126,
      vB: 0.0530632,
      BD: 0.0530632,
      CD: 0.0530632,
      DD: 0.148577
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "1C", "2C", "3C", "4C", "5C", "3B", "6C", "7C", "8C", "9C", "AD", "vB", "BD", "CD", "DD", "j", "k", "", "", ""],
    E: "Samsung Internet",
    F: {
      I: 1461024000,
      "1C": 1481846400,
      "2C": 1509408000,
      "3C": 1528329600,
      "4C": 1546128000,
      "5C": 1554163200,
      "3B": 1567900800,
      "6C": 1582588800,
      "7C": 1593475200,
      "8C": 1605657600,
      "9C": 1618531200,
      AD: 1629072000,
      vB: 1640736000,
      BD: 1651708800,
      CD: 1659657600,
      DD: 1667260800,
      j: 1677369600,
      k: 1684454400
    }
  },
  Q: {
    A: {
      "4B": 0.127857
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "4B", "", "", ""],
    E: "QQ Browser",
    F: {
      "4B": 1663718400
    }
  },
  R: {
    A: {
      ED: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "ED", "", "", ""],
    E: "Baidu Browser",
    F: {
      ED: 1663027200
    }
  },
  S: {
    A: {
      FD: 0.066708,
      GD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "FD", "GD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      FD: 1527811200,
      GD: 1631664000
    }
  }
};
export default exports;